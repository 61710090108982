<template>
  <a-spin :spinning="loading">
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="运营商账户">
            <a :href="'/carrier_accounts/'+data.carrier_account_id+'/info'">
              {{ data.carrier_account_name }}
            </a>
          </a-descriptions-item>
          <a-descriptions-item label="运营商套餐">
            {{ data.carrier_product_name }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商订单ID">
            {{ data.newtech_order_id }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商订单时间">
            {{ data.newtech_order_change_time }}
          </a-descriptions-item>
          <a-descriptions-item label="套餐周期">
            {{ data.carrier_product_service_cycle }}
          </a-descriptions-item>
          <a-descriptions-item label="共享属性">
            {{ data.traffic_share_property }}
          </a-descriptions-item>
          <a-descriptions-item label="其他服务">
            {{ data.other_services }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="运营商状态">
            {{ data.card_status }}
          </a-descriptions-item>
          <a-descriptions-item label="断网状态">
            {{ data.net_status }}
          </a-descriptions-item>
          <a-descriptions-item label="管理停机">
            {{ data.carrier_manage_stop_status }}
            <a-tooltip>
              <template slot="title">
                {{ data.carrier_manage_stop_reason }}
              </template>
              <a-icon
                class="simiot-cursor-pointer"
                type="question-circle"
                theme="twoTone"
              />
            </a-tooltip>
          </a-descriptions-item>
          <a-descriptions-item label="APN">
            {{ data.apn_parameters }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-7"
        >
          <a-descriptions-item label="开卡日期">
            {{ data.open_card_date }}
          </a-descriptions-item>
          <a-descriptions-item label="服务周期">
            {{ data.carrier_service_cycle }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商测试流量">
            {{ data.carrier_test_traffic }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商测试期">
            {{ data.carrier_test_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商沉默期">
            {{ data.carrier_silent_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商激活时间">
            {{ data.carrier_activated_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { findSimCardCarrierInfo } from '@/api/sim_card'

export default {
  name: 'ShowSimCardCarrierInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardCarrierInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
